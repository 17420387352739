import Vue from 'vue'
import Vuex from 'vuex'
import noCache from './modules/noCache'//引入模块
import createPersistedState from "vuex-persistedstate"
import { listSdk } from '@/api/api.js'
import { Dialog } from 'vant'


Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    noCache,
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
      //不持久化noCache下的状态
      let res = {}
      for (const key in val) {
        if (Object.hasOwnProperty.call(val, key)) {
          const element = val[key];
          if (key !== 'noCache') {
            res[key] = element
          }
        }
      }
      return res
    }
  })],
  state: {
    lng: 0,//当前经度
    lat: 0,//当前纬度
    nowAddress: '',//当前地址
    distance: 0,//当前距离门店距离
    resetSize: 0,//用于重新计算页面结构
    loadingStatus: false,// 全局loading状态发请求前变为true
    batchCode: '',//批次code   url传参接收然后存储于state  暂定具体看后端
    taskCode: '',//任务code url传参接收然后存储于state  暂定具体看后端
    empPk: '',//emppk url传参接收然后存储于state  暂定具体看后端
    taskDetailsInfo: {},//子任务详情的相关信息  因为下一个接口不返回所以存储保留
    //任务详情页前端缓存的数据
    taskDetailsCache: {
      performCode: '',
      dataList: [],
      keepDataList: []
    },
    openOrScheduleId: {},//openid 和 scheduleId
    userInfo: {},//用户信息 包含大量内容
    taskTitle: '',//模块名称
    auditDetailsItem: {},//我的任务服务费数组传参
    urlInfo: {},//url传参 charge使用
    positionLoading: false,
    //获取SDK
    getSdk: async function (sdkCode, cb) {
      // { sdkCode: "CRMEX-MSGPUSH", name: "消息推送", memo: "关闭此功能后，可能无法收到公众号推送的消息", sdkStatus: "1" }
      // { sdkCode: "CRMEX-WXLOCATION", name: "微信定位", memo: "关闭此功能后，微信可能无法获取到您的定位", sdkStatus: "1" }
      // { sdkCode: "CRMEX-TXFACEVERIFY", name: "腾讯人脸核身", memo: "关闭此功能后，将无法使用米咖的刷脸功能", sdkStatus: "1" }
      // { sdkCode: "CRMEX-SMS", name: "短信验证码", memo: "关闭此功能后，将无法修改手机号", sdkStatus: "1" }
      // { sdkCode: "CRMEX-OCR", name: "OCR识别", memo: "关闭此功能后，将无法进行实名认证和银行卡认证", sdkStatus: "1" }
      // { sdkCode: "CRMEX-BANKCARD", name: "银行卡信息校验", memo: "关闭此功能后，将无法进行银行卡认证", sdkStatus: "1" }
      let res = await listSdk()
      let flag = false
      let elName = '';
      res.data.data.sdkList.forEach((element) => {
        if (element.sdkCode === sdkCode) {
          elName = element.name
          if (element.sdkStatus == '1') {
            flag = true
          }
        }
      });
      if (flag) {
        return true
      } else {
        Dialog.confirm({
          title: '提示',
          message: `您关闭了【${elName}】，激活任务无法正常使用，请先前往打开设置`,
          confirmButtonText: '确认',
          cancelButtonText: '暂不打开',
          cancelButtonColor: 'black',
          confirmButtonColor: 'black'
        })
          .then(() => {
            location.href = res.data.data.url
          })
          .catch(() => {
            cb()
          })
        return false
      }
    },
    //禁用返回键
    disableBack: function () {
      //浏览器返回键事件
      pushHistory();
      window.addEventListener("popstate", function (e) {
        pushHistory(); //注，此处调用，可以让用户一直停留着这个页面
      }, false);
      function pushHistory() {
        var state = {
          title: "title",
          url: "#"
        };
        window.history.pushState(state, "title", "#");
      }
    },
  },
  mutations: {
    changelng(state, lng) {
      state.lng = lng;
    },
    changelat(state, lat) {
      state.lat = lat;
    },
    changeNowAddress(state, nowAddress) {
      state.nowAddress = nowAddress;
    },
    changeDistance(state, distance) {
      state.distance = distance;
    },
    changeloadingStatus(state, loadingStatus) {
      state.loadingStatus = loadingStatus
    },
    changePositionLoading(state, positionLoading) {
      state.positionLoading = positionLoading
    },
    batchCode(state, batchCode) {
      state.batchCode = batchCode;
    },
    taskCode(state, taskCode) {
      state.taskCode = taskCode;
    },
    empPk(state, empPk) {
      state.empPk = empPk;
    },
    taskDetailsInfo(state, taskDetailsInfo) {
      state.taskDetailsInfo = taskDetailsInfo;
    },
    openOrScheduleId(state, openOrScheduleId) {
      state.openOrScheduleId = openOrScheduleId;
    },
    userInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    taskTitle(state, taskTitle) {
      state.taskTitle = taskTitle;
    },
    auditDetailsItem(state, auditDetailsItem) {
      state.auditDetailsItem = auditDetailsItem;
    },
    urlInfo(state, urlInfo) {
      state.urlInfo = urlInfo;
    },
    resetSize(state) {
      state.resetSize = state.resetSize + 1;
    },
    taskDetailsCache(state, taskDetailsCache) {
      state.taskDetailsCache = taskDetailsCache
    }
  },
  actions: {
  }
})
