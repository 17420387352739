import request from '../utils/request.js'

const emptyPromise = () => Promise.resolve(null)

// 众安对接-普乐师获取投保页面链接
export function getInsureUrl(data = {}) {
  return request().post('/api/con/v1/insureJoint/getInsureUrl', data)
}

// 众安对接-普乐师主动请求订单状态
export function getInsureResult(params = {}) {
  return request().get('/api/con/v1/insureJoint/getInsureResult', { params })
}

// 获取触点当天保险情况
export function getInsureStatus(data = {}) {
  return request().post('/api/con/v1/insure/getInsureStatus', data)
}
