import request from '../utils/request.js'
import requestSilent from '../utils/requestSilent.js'
import store from '../store'
 
export function getSalesPrjInfo(params) {
  return request().get(`/api/con/v1/task/getSalesPrjInfo`, {
    params: {
      openId: store.state.openOrScheduleId.openId,
      scheduleId: store.state.openOrScheduleId.scheduleId,
      ...params
    }
  })
}
//任务上报页面------------------------------------------------------->
//获取子任务列表
export function listSubTask(params) {
  return request().get(`/api/con/v1/task/listSubTask`, {
    params: {
      batchCode: store.state.batchCode,
      taskCode: store.state.taskCode,
      userCode: store.state.empPk,
      taskDate: store.state.userInfo.attdate,
      ...params
    }
  })
}
//获取子任务详情
export function listSubTaskDetail(params) {
  return request().get(`/api/con/v1/task/listSubTaskDetail`, {
    params: {
      userCode: store.state.empPk,
      taskDate: store.state.userInfo.attdate,
      ...params
    }
  })
}
//任务提报
export function reportSubTaskDetail(data) {
  // console.log(data)
  return request().post(`/api/con/v1/task/reportSubTaskDetail`, data)
}
//上传图片
export function imageUpload(formData) {
  return request().post(`/api/con/v1/task/fileUpload`, formData)
}
//上传 IP+设备信息
export function addHardwareLog(data) {
  return requestSilent().post(`/api/con/v1/log/addHardwareLog`, data)
}

//校验任务状态  res.data.data  0 代表可进入  1代表未开始  2代表已结束
export function checkTaskStatus(params) {
  return request().get(`/api/con/v1/task/checkTaskStatus`, {
    params: {
      performStartTime: params.performStartTime === null ? '' : params.performStartTime,
      performEndTime: params.performEndTime === null ? '' : params.performEndTime,
      isDone: params.isDone
    }
  })
}
//获取是否需要进行刷脸任务
export function getFace(params) {
  params.date = new Date().getTime()
  return request().get(`/api/con/v1/task/userFaceCompare`, {
    params
  })
}
//查询sdk
export function listSdk() {
  let params = {
    date: new Date().getTime(),
    openid: store.state.userInfo.userOpenid
  }
  return request().get(`/api/con/v1/task/listSdk`, {
    params
  })
}

// 我的任务服务费页面 ---------------------------------------->
// 任务模块对应的code 获取标签
export function listBaseTask() {
  return request().get(`/api/con/v1/listBaseTask`)
}
//我的服务费列表   1001431484测试使用之后删除
export function myTask(data) {
  // console.log(data)
  return request().post(`/api/con/v1/task/myTask`, { openId: store.state.urlInfo.userOpenId, ...data })
}
//我的服务费详情
export function myTaskDetail(reportId) {
  return request().get(`/api/con/v1/task/myTaskDetail?reportId=${reportId}`)
}

//根据type查找授权协议
export function getAgreementByType() {
  return request().get(` /rest/agreement/getAgreementByType?type=*`)
}
//根据openId查询是否授权
export function checkAccordProvide() {
  return request().get(
    `/api/con/v1/task/checkAccordProvide?openid=${store.state.openOrScheduleId.openId}&agreementCode=2023-photo-collect`//待修改code
  )
}
//根据openId授权协议
export function accordProvide() {
  return request().post(`/api/con/v1/task/accordProvide`, {
    cOpenId: store.state.openOrScheduleId.openId,
    cAgreementCode: '2023-photo-collect',//待修改code
    cAgreementTitle: '个人照片采集和使用授权'//title需要对应修改
  })
}

//服务激活页面
//获取任务详情
export function getFWJH(params = {}) {
  params.date = new Date().getTime()
  return request().get(`/api/con/v1/task/listSubTask`, {
    params: {
      batchCode: store.state.batchCode,
      taskCode: store.state.taskCode,
      userCode: store.state.empPk,
      taskDate: store.state.userInfo.attdate,
      ...params
    }
  })
}
//提报任务
export function reportFWJH(data) {
  return request().post(`/api/con/v1/task/reportSubTaskDetail`, data)
}
