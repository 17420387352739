import axios from 'axios'
import store from '../store'

const handleInit = () => {
  ;(function (a, h, c, b, f, g) {
    a['UdeskApiObject'] = f
    a[f] =
      a[f] ||
      function () {
        ;(a[f].d = a[f].d || []).push(arguments)
      }
    g = h.createElement(c)
    g.async = 1
    g.src = b
    c = h.getElementsByTagName(c)[0]
    c.parentNode.insertBefore(g, c)
  })(window, document, 'script', 'https://mecoolchina.s2.udesk.cn/im_client/js/udeskApi.js', 'ud')
  axios({
    method: 'GET',
    url: '/api/con/v1/insure/aiService',
    params: {
      openId: store.state.openOrScheduleId.openId
    }
  }).then((res) => {
    let data = res.data
    if (data != '' && data != 'null') {
      init({
        nonce: data.nonce,
        signature: data.signature,
        timestamp: data.timestamp,
        web_token: data.web_token,
        name: data.name,
        nickNname: data.nicNname,
        openid: data.openId,
        empPk: data.empPk,
        name: data.name,
        provinceAdName: data.provinceAdName,
        cityAdName: data.cityAdName
      })
    }
  })
}

const init = (options) => {
  try {
    ud({
      code: '12321',
      link: 'https://mecoolchina.s2.udesk.cn/im_client/?web_plugin_id=11485',
      selector: '.ud-link',
      onlineText: '联系客服',
      offlineText: '请留言',

      onReady: function () {
        console.log('初始化完成')
      },
      customer: {
        nonce: options.nonce,
        signature: options.signature,
        timestamp: options.timestamp,
        web_token: options.web_token,
        c_name: options.name,
        c_nickname: options.nickNname,
        c_cf_copenid: options.openid,
        c_cf_促销员id: options.empPk,
        c_cf_促销员姓名: options.name,
        c_cf_省市: options.provinceAdName,
        c_cf_城市: options.cityAdName
      }
    })
  } catch (error) {
    console.log('error: ', error)
  }
}

export default handleInit
