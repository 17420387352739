<template>
  <div v-if="showbox">
    <div v-if="showbox">
      <NoUser v-if="noUser" />
      <TaskList v-else />
    </div>
  </div>
</template>
<script>
import TaskList from '@/components/entrance/TaskList.vue'
import NoUser from '@/components/entrance/NoUser.vue'
import { getSalesPrjInfo } from '@/api/api.js'
import { Toast } from 'vant'
export default {
  components: {
    TaskList,
    NoUser
  },
  data() {
    return {
      // startTask: false, // 判定是否是开始结束任务是的话加载开始任务组件  不是的话加载列表
      noUser: true, //若未获取到用户信息不展示页面
      showComponent: false, //跑完获取信息的接口才渲染组件
      showbox: false
    }
  },
  methods: {
    //获取url传参
    getUrlData() {
      var url = window.location.search
      var obj = {}
      var reg = /[?&][^?&]+=[^?&]+/g
      var arr = url.match(reg)
      if (arr) {
        arr.forEach(function (item) {
          var tempArr = item.substring(1).split('=')
          var key = decodeURIComponent(tempArr[0])
          var val = decodeURIComponent(tempArr[1])
          if (val !== 'undefined' && val !== 'null') {
            //null 和 undefined不要
            obj[key] = val
          }
        })
        this.$store.commit('openOrScheduleId', obj)
        this.getTitle(obj.taskCode)
        // console.log(obj);
        return obj
      }
    },
    //获取用户数据
    getUserInfo() {
      // 测试环境http
      getSalesPrjInfo()
        .then((res) => {
          // 假数据 仅uat环境生效
          if (process.env.VUE_APP_DEVELOP_MOCK === 'true') {
            res.data = {
              success: true,
              // data: {
              //   schendtime: null,
              //   terminalProvince: '山东省',
              //   userName: '姜新春',
              //   userCode: '1001442999',
              //   userOpenid: 'ot3VXxMkPFF6nALV6wf7Yi_f8wtE',
              //   // userOpenid: 'ot3VXxO0SWhw_6Xl_ZCDpNrC96Fw', //董  uat 服务激活有数据
              //   // userName: '董祥宇', //董  uat 服务激活有数据
              //   // userCode: '1001443038', //董  uat 服务激活有数据
              //   attdate: '2022-10-18', //服务激活需要改成当天
              //   schstarttime: null,
              //   taskPackageCode: 'WPBBN37PH8UPV',
              //   terminalName: 'JUSCO青岛黄岛店',
              //   terminalLongitude: '120.19445527912701',
              //   taskBatchCode: 'BATCHTUESEPF3YN7B',
              //   projectCode: '0705AGX0705A',
              //   terminalCity: '青岛市',
              //   terminalTown: null,
              //   terminalArea: '青岛市',
              //   terminalLatitude: '35.96067545419747',
              //   terminalAddress: '青岛开发区长江中路419号',
              //   terminalCode: '3702010010041400267424',
              //   projectName: '至初牛奶',
              //   scheduleId: '27505093158',
              //   backUrl: 'https://pgs.mecoolchina.com/PlusApp/index.html#home'
              // }
              // 这套数据JPCJ有题
              // data: {
              //   attdate: '2023-03-14',
              //   backUrl: 'https://pgs.mecoolchina.com/PlusApp/index.html#home',
              //   projectCode: 'QWEASGX0309',
              //   projectName: '任务逻辑/销量GX月',
              //   scheduleId: '27585427718',
              //   schendtime: null,
              //   schstarttime: '14:34',
              //   taskBatchCode: 'BATCHBAZXNU2LUQ6B',
              //   taskPackageCode: 'PLANBPD6JLE56DH',
              //   terminalAddress: '贵州省凯里市世纪诚1号',
              //   terminalArea: '凯里市',
              //   terminalCity: '黔东南苗族侗族自治州',
              //   terminalCode: '5226010010041400307648',
              //   terminalLatitude: '26.573079425675388',
              //   terminalLongitude: '107.98746889069089',
              //   terminalName: '大诚友苏果超市凯里市世纪城路店',
              //   terminalProvince: '贵州省',
              //   terminalTown: null,
              //   userCode: '1001438261',
              //   userName: '李秋林',
              //   userOpenid: 'ot3VXxAJgMQ7tdw3lYAChwRTfBGM'
              // }
              // 陈列上报时有逻辑数据
              // data: {
              //   attdate: '2023-03-09',
              //   backUrl: 'https://pgs.mecoolchina.com/PlusApp/index.html#home',
              //   projectCode: 'QWEASGX0309',
              //   projectName: '任务逻辑/销量GX月',
              //   scheduleId: '27584356087',
              //   schendtime: null,
              //   schstarttime: '15:58',
              //   taskBatchCode: 'BATCHBDDWDH26RY6',
              //   taskPackageCode: 'PLANBP4VPKGS2DH',
              //   terminalAddress: '贵州省凯里市清平南路116号',
              //   terminalArea: '凯里市',
              //   terminalCity: '黔东南苗族侗族自治州',
              //   terminalCode: '5226010010041400307651',
              //   terminalLatitude: '26.582891008247685',
              //   terminalLongitude: '107.97403697755738',
              //   terminalName: '便捷超市凯里市清平南路店',
              //   terminalProvince: '贵州省',
              //   terminalTown: null,
              //   userCode: '1001438261',
              //   userName: '李秋林',
              //   userOpenid: 'ot3VXxAJgMQ7tdw3lYAChwRTfBGM'
              // }
              // 赠品上报等有逻辑
              // data: {
              //   attdate: '2023-03-30',
              //   backUrl: 'https://pgs.mecoolchina.com/PlusApp/index.html#home',
              //   projectCode: 'YTRFDGX0323A',
              //   projectName: '测试对比数据GX',
              //   scheduleId: '27588891774',
              //   schendtime: null,
              //   schstarttime: '15:52',
              //   taskBatchCode: 'BATCHFYJ65SK6VAA3',
              //   taskPackageCode: 'PLAN34CMBE2KMD2',
              //   terminalAddress: '深圳市南山区粤海街道海珠社区文心五路33号',
              //   terminalArea: '南山区',
              //   terminalCity: '深圳市',
              //   terminalCode: '4403010510043788310145',
              //   terminalLatitude: null,
              //   terminalLongitude: null,
              //   terminalName: 'Ole深圳文心五路海岸城店',
              //   terminalProvince: '广东省',
              //   terminalTown: null,
              //   userCode: '1001438261',
              //   userName: '李秋林',
              //   userOpenid: 'ot3VXxAJgMQ7tdw3lYAChwRTfBGM'
              // },
              //销量上报99道题
              data: {
                attdate: '2023-04-10',
                backUrl: 'https://pgs.mecoolchina.com/PlusApp/index.html#home',
                projectCode: 'ZXCVBGX0331',
                projectName: '四月底结束项目0331',
                scheduleId: '27591223324',
                schendtime: null,
                schstarttime: '09:44',
                taskBatchCode: 'BATCH7RHAYKH7L6F',
                taskPackageCode: 'PLANFVN2NFZ5ZY4',
                terminalAddress: '北京市顺义区顺沙路高丽营路12号',
                terminalArea: '顺义区',
                terminalCity: '北京市',
                terminalCode: '1101000013171379153119',
                terminalLatitude: '40.175249',
                terminalLongitude: '116.509609',
                terminalName: '金色时光北京市高丽营路1店',
                terminalProvince: '北京市',
                terminalTown: null,
                userCode: '1001438261',
                userName: '李秋林',
                userOpenid: 'ot3VXxAJgMQ7tdw3lYAChwRTfBGM'
              }
            }
          }
          // 假数据 ↑
          if (res.data.success) {
            // console.log(this.$store.state.openOrScheduleId);
            this.$store.commit('userInfo', res.data.data)
            let taskCode = this.$store.state.openOrScheduleId.taskCode
            this.$store.commit('taskCode', taskCode)
            this.$store.commit('empPk', res.data.data.userCode)
            this.$store.commit('batchCode', res.data.data.taskBatchCode)
            if (taskCode === 'FWJH') {
              //服务激活单独走页面
              this.$router.replace('/faceTask')
            } else {
              this.noUser = false
              this.showComponent = true
              this.showbox = true
            }
            Toast.clear()
          } else {
            this.noUser = true
            this.showComponent = true
            this.showbox = true
            Toast.clear()
          }
        })
        .catch(() => {
          this.noUser = true
          this.showComponent = true
          this.showbox = true
          Toast.clear()
        })
    },
    //筛选title应该显示什么
    getTitle(taskCode) {
      let title = ''
      switch (taskCode) {
        case 'KSZP':
          title = '开始照片'
          break
        case 'JSZP':
          title = '结束照片'
          break
        case 'XLSB':
          title = '销量上报'
          break
        case 'KCSB':
          title = '库存提报'
          break
        case 'KSZP':
          title = '开始照片'
          break
        case 'CLSB':
          title = '陈列上报'
          break
        case 'SDJL':
          title = '晒单奖励'
          break
        case 'FWJH':
          title = '服务激活'
          break
        case 'JPSB':
          title = '竞品采集'
          break
        default:
          title = ''
          break
      }
      this.$store.commit('taskTitle', title)
    }
  },
  created() {
    this.getUrlData()
    this.getUserInfo() //打开
  },
  mounted() {
    this.$store.commit('noCache/cached', true)
    this.$gobal.controlOptionMenu()
    this.$store.state.disableBack()
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: '加载中'
    })
  }
}
</script>
