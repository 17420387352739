import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.less'
import gobal from '@/gobal/gobal'
import CustomerService from '@/utils/CustomerService'
Vue.prototype.$gobal = gobal

// AI客服
CustomerService()

Vue.filter('money', (val) => {
  if (val == null) {
    return ''
  } else {
    // num.toLocaleString('zh', { maximumFractionDigits: 2, useGrouping: false }); //1234.46
    return (+val).toLocaleString('zh', { maximumFractionDigits: 2 })
  }
})

// 判断是否是微信
var re = /MicroMessenger/i
var isWechat = re.test(navigator.userAgent)

// 判断是否是电脑端
function IsPC() {
  const userAgentInfo = navigator.userAgent
  const Agents = [
    'Android',
    'iPhone',
    'iPad',
    'Windows Phone',
    'SymbianOS', // 塞班
    'PlayBook',
    'BB10', // 黑莓
    'KFAPWI' // kindle
  ]
  let flag = true
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}
// 针对iframe嵌入的页面直接mount
if (location.pathname === '/taskDetailsForIframe') {
  mountApp()
} else {
  const isDev = process.env.VUE_APP_DEVELOP_MODEL === 'true'
  if (!isWechat && !isDev) {
    document.write('请在微信客户端打开')
  } else if (IsPC() && !isDev) {
    // 如果是电脑端，则跳转到‘明酷官网’
    location.href = 'http://www.mecoolchina.com/'
  } else {
    mountApp()
  }
}

function mountApp() {
  Vue.config.productionTip = false
  Vue.use(Vant)
  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')
}
