<template>
  <div class="taskList">
    <van-nav-bar :title="this.$store.state.taskTitle" left-arrow @click-left="onClickLeft" @click-right="onClickRight">
    </van-nav-bar>
    <div
      :style="{
        padding: `0 2.7% ${recommendHeight + 10}px`,
        overflow: 'auto',
        boxSizing: 'border-box',
        height: height
      }"
    >
      <div v-for="(item, index) in dataList" :key="item.sort">
        <div class="cardBox" @click="toDetails(item)">
          <div class="flexBox">
            <div class="indexBox"><span class="required" v-if="item.must">*</span> {{ index + 1 }}、</div>
            <div>
              {{ item.title }}
              <span style="margin-left: 5px">(￥{{ item.price }})</span>
              <div style="font-weight: 100; margin-top: 5px">
                <span v-if="item.perfromStatus == 0">全天可执行</span>
                <span v-else-if="item.perfromStatus == 1"
                  >{{ item.performStartTime }}~{{ item.performEndTime }}可执行</span
                >
                <span class="tips" v-if="item.isDone">已完成</span>
                <span class="tipsnotstart" v-else-if="item.taskStatus === 1">未开始</span>
                <span class="tipsend" v-else-if="item.taskStatus === 2">已结束</span>
                <span class="tipsgoon" v-else-if="item.taskStatus === 3">进行中</span>
              </div>
            </div>
          </div>
          <div class="arrow"><img src="@/assets/icon/1-右.svg" alt="" /></div>
        </div>

        <van-empty v-if="showEmpty" description="暂无任务" />
      </div>
      <!-- 投保区域 -->
      <Insurance
        v-if="$store.state.taskCode === 'KSZP' && dataList.length"
        ref="insurance"
        :tenCode="dataList[0].tenCode"
        @getRecommendHeight="getRecommendHeight"
        @getInsureStatus="getInsureStatus"
      />
    </div>

    <!-- 点击返回按钮根据条件弹出推荐投保弹窗 -->
    <van-action-sheet v-model="showRecommend" title="" :close-on-click-overlay="false" :closeable="false">
      <div class="popup-container">
        <van-icon class="close" name="cross" @click="goBack" />
        <img class="img" src="@/assets/insurance.jpg" alt="" />
        <div class="message">
          购买保险服务由第三方“太平保险经纪“提供，请仔细阅读相关《用户服务协议》。点击
          <span class="link">【立即投保】</span>
          即授权并同意使用您的手机号以及实名信息进行投保
        </div>

        <van-row class="footer" gutter="20">
          <van-col span="10">
            <van-button round block plain color="#c9976c" @click="handleRecommendAction(false)"> 不考虑</van-button>
          </van-col>
          <van-col span="14">
            <van-button round block color="#c9976c" @click="handleRecommendAction(true)">立即投保</van-button>
          </van-col>
        </van-row>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { listSubTask, checkTaskStatus, addHardwareLog } from '@/api/api.js'
import Insurance from '@/components/Insurance/index.vue'
import { Dialog } from 'vant'
import UA from 'ua-device'
export default {
  components: { Insurance },
  data() {
    return {
      title: '', // 模块名称
      height: '500px',
      dataList: [],
      showEmpty: false,
      showRecommend: false,
      recommendHeight: 0, // 推荐保单任务盒子高度
      insureStatus: '0' // 投保状态
    }
  },
  computed: {
    // 开始照片任务是否完成
    isKSZPFinished() {
      if (this.dataList.length) {
        return this.dataList.every((item) => item.isDone)
      } else {
        return false
      }
    }
  },
  methods: {
    goBack() {
      location.href = this.$store.state.userInfo.backUrl || 'javascript:history.back();'
    },
    onClickLeft() {
      if (this.$store.state.taskCode !== 'KSZP') {
        this.goBack()
        return
      }
      const { mustPolicy, taskPackageCode } = this.$store.state.userInfo || {}
      // mustPolicy：1 强制投保
      // mustPolicy：0 非强制
      console.log('开始照片任务全部完成了吗？ ', this.isKSZPFinished)
      if (mustPolicy == 1 && (!this.isKSZPFinished || !['2', '3'].includes(this.insureStatus))) {
        // 强制任务，开始照片任务或保险任务未完成，弹出提示

        Dialog.confirm({
          title: '确认退出？',
          message: `开始照片任务全部完成后，才能执行其他任务哦`,
          confirmButtonText: '留下',
          cancelButtonText: '继续退出',
          // cancelButtonColor: '#ee0a24',
          confirmButtonColor: '#24ADFE'
        })
          .then(() => {
            // on confirm
            console.log('留下')
          })
          .catch(() => {
            // on cancel
            console.log('继续退出')
            this.goBack()
          })
      } else if (mustPolicy == 0 && this.isKSZPFinished && !['2', '3'].includes(this.insureStatus)) {
        // 非强制投保任务，开始照片任务全部完成并且投保任务未完成
        const recommendMap = sessionStorage.getItem('recommendMap')
          ? JSON.parse(sessionStorage.getItem('recommendMap'))
          : {}
        if (!recommendMap[taskPackageCode]) {
          // 非强制任务
          this.showRecommend = true
          recommendMap[taskPackageCode] = true
          sessionStorage.setItem('recommendMap', JSON.stringify(recommendMap))
          return
        }
        this.goBack()
      } else {
        this.goBack()
      }
    },
    onClickRight() {
      // Toast("按钮");
    },
    //跳转详情
    toDetails(item) {
      //先校验任务可做时间
      checkTaskStatus(item).then((res) => {
        if (res.data.data == 1) {
          Dialog.alert({
            title: '提示',
            message: `此任务还未开始，请在${item.performStartTime}~${item.performEndTime}之间来做任务`,
            confirmButtonText: '我知道了',
            // cancelButtonColor: '#ee0a24',
            confirmButtonColor: '#24ADFE'
          })
          return
        } else if (res.data.data == 2) {
          Dialog.alert({
            title: '提示',
            message: `非常遗憾，此任务已结束，请下次在${item.performStartTime}~${item.performEndTime}之间来做任务`,
            confirmButtonText: '我知道了',
            // cancelButtonColor: '#ee0a24',
            confirmButtonColor: '#24ADFE'
          })
          return
        }
        this.$store.commit('taskDetailsInfo', item)
        this.$router.push(`/taskDetails/${item.taskSubId}/${item.code}`)
      })
    },
    //获取列表
    getListSubTask() {
      if (
        this.$store.state.batchCode &&
        this.$store.state.taskCode &&
        this.$store.state.empPk &&
        this.$store.state.userInfo.attdate
      ) {
        listSubTask().then((res) => {
          this.dataList = res.data.data
          // 假数据
          // this.dataList=[{title:1,price:2,perfromStatus:0,taskStatus:1,taskSubId:65, code:12,performStartTime:'2022-07-18 8:00',performEndTime:'2022-07-18 20:00',isDone:1}]
          // 假数据
          this.$store.commit('taskTitle', res.data.data[0].moduleTitle)
          if (this.dataList.length === 0) {
            this.showEmpty = true
          }
        })
      } else {
        this.showEmpty = true
      }
    },

    getRecommendHeight(height) {
      this.recommendHeight = height
    },
    getInsureStatus(status) {
      this.insureStatus = status
    },
    // 立即投保
    toInsurance() {
      this.$refs.insurance.toInsurance()
    },
    // 获取设备信息
    async getUserAgent(msg) {
      // //调用上传设备信息 ip接口 用户信息
      var output = new UA(navigator.userAgent) //UA获取到的对象
      var sss = navigator.userAgent.split(';')
      //判断UA里边有没有Build信息，通过这个拿到安卓的具体机型
      var i = -1
      sss.forEach((element, index) => {
        if (element.includes('Build/')) {
          i = index
        }
      })
      let newMobile //如果是安卓手机返回安卓的型号
      if (i > -1) {
        newMobile = sss[i].substring(0, sss[i].indexOf('Build/'))
      }
      // console.log(output.device.identified ? output.device.model : newMobile)//设备型号
      // console.log(output.browser.name + ' ' + output.browser.version.original)//浏览器版本
      // console.log(output.os.name + ' ' + output.os.version.original)//系统版本
      let ip = ''
      try {
        ip = returnCitySN.cip
      } catch {}
      await addHardwareLog({
        funTitle: this.$store.state.taskCode + this.$store.state.taskTitle + msg,
        userAgents: navigator.userAgent,
        resolvingPower: `${screen.width}x${window.screen.height}`,
        ip: ip,
        userCode: this.$store.state.openOrScheduleId.openId, //传的字段是userCode实际传的是openId
        browser: output.browser.name + ' ' + output.browser.version.original,
        os: output.os.name + ' ' + output.os.version.original,
        device: output.device.identified ? output.device.model : newMobile
      })
    },
    // 推荐投保操作
    handleRecommendAction(flag) {
      this.getUserAgent(`-立即投保${flag ? '' : '不考虑'}`)
      if (flag) {
        this.toInsurance()
      } else {
        this.goBack()
      }
    }
  },
  mounted() {
    // document.title = this.title;
    this.height = document.documentElement.clientHeight - 54 + 'px'
    this.getListSubTask()
    // this.$refs.insurance.getInsureStatus()
  }
}
</script>
<style lang="less" scoped>
.flexBox {
  display: flex;
}
.cardBox {
  background: #fff;
  margin-top: 10px;
  padding: 17px 11.9% 17px 7.2%;
  line-height: 25px;
  color: #333;
  font-weight: 900;
  border-radius: 4px;
  position: relative;
  .indexBox {
    position: relative;
    .required {
      position: absolute;
      color: #ff4d4f;
      left: -12px;
    }
  }
  .arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 3.3%;
    top: 21px;
    img {
      display: block;
    }
  }
  .tips {
    background: #f6ffed;
    border: 1px solid #b7eb8f;
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    color: #52c41a;
    font-weight: 400;
    margin-left: 10px;
    padding: 3px 13px;
  }
  .tipsnotstart {
    background: #ffffff;
    border: 1px solid #9da4b1;
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    color: #9da4b1;
    font-weight: 400;
    margin-left: 10px;
    padding: 3px 13px;
  }
  .tipsend {
    background: #ffffff;
    border: 1px solid #ff6633;
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    color: #ff6633;
    font-weight: 400;
    margin-left: 10px;
    padding: 3px 13px;
  }
  .tipsgoon {
    background: #ffffff;
    border: 1px solid #4badff;
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    color: #4badff;
    font-weight: 400;
    margin-left: 10px;
    padding: 3px 13px;
  }
}

.popup-container {
  position: relative;
  background-color: #f5f5f5;
  .close {
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
  }

  .img {
    width: 100%;
  }

  .message {
    padding: 0 20px 20px;
    font-size: 12px;
    color: #999;
    .link {
      color: #c9976c;
    }
  }
  .footer {
    padding: 0 30px 20px;
  }
}
</style>
