import axios from 'axios'
import store from '../store'
import { Toast } from 'vant'

export default function () {
    const service = axios.create({
        // timeout: 5000, // 请求超时时间
    })


    // 请求拦截器
    service.interceptors.request.use(
        (config) => {
            store.commit('changeloadingStatus', true)
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '加载中',
            });
            // 在发送请求前做些什么
            return config
        },
        (err) => {
            // 在请求错误的时候做些什么
            store.commit('changeloadingStatus', false)
            return Promise.reject(err)
        }
    )

    // 响应拦截器
    service.interceptors.response.use(
        (response) => {
            // 在请求成功对响应数据做处理
            let ignore = response.request.responseURL.includes('/api/con/v1/log/addHardwareLog')  //特定接口不会进行报错 如获取用户设备信息
            store.commit('changeloadingStatus', false)
            if (response.data.success) {
                Toast.clear();
                return Promise.resolve(response)
            } else {
                Toast.clear();
                if (!ignore&&response.data.errorCode!="PERFORMETIMETASK-END"&&response.data.errorCode!="PERFORMETIMETASK-START") {
                    Toast(response.data.errorMessage)
                }
                return Promise.resolve(response)
                // return Promise.resolve({ data: { data: '' } })
            }
        },
        (error) => {
            // 在响应错误的时候做些什么
            Toast.clear();
            store.commit('changeloadingStatus', false)
            Toast('服务器开了个小差')
            return Promise.reject(error)
        }
    )
    return service
}
