import axios from 'axios'

export default function () {
    const service = axios.create({
        // timeout: 5000, // 请求超时时间
    })

    // 请求拦截器
    service.interceptors.request.use(
        (config) => {
            // 在发送请求前做些什么
            return config
        },
        (err) => {
            // 在请求错误的时候做些什么
            return Promise.reject(err)
        }
    )

    // 响应拦截器
    service.interceptors.response.use(
        (response) => {
            // 在请求成功对响应数据做处理
            return Promise.resolve(response)
        },
        (error) => {
            // 在响应错误的时候做些什么
            return Promise.reject(error)
        }
    )
    return service
}
