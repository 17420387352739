<template>
  <div>
    <!-- 强制保险任务 -->
    <div
      ref="insuranceBox"
      class="cardBox"
      :class="{ 'recommend-box': !isMust, disabled: [2, 3].includes(insureStatus) }"
    >
      <div class="header">推荐</div>
      <div @click="toInsurance">
        <div class="flexBox">
          <div class="indexBox" style="margin-right: 26px"><span class="required">*</span></div>
          <div>
            每日保险申购
            <div style="font-weight: 100; margin-top: 5px">
              <span>全天可执行</span>

              <span class="tipsnotstart" v-if="insureStatus === '0' && isMust">待执行</span>
              <span class="tipsgoon" v-else-if="insureStatus === '1'">出单中</span>
              <span class="tips" v-else-if="insureStatus === '2'">已完成</span>
              <span class="tipsend" v-else-if="insureStatus === '3'">无法投保</span>
            </div>
          </div>
        </div>
        <div class="arrow"><img src="@/assets/icon/1-右.svg" alt="" /></div>
        <div class="insurance-message">
          <p>保障有效时间为购买生效时间起至当日晚24点</p>
          <p v-if="insureStatus === '0'">
            购买保险服务由第三方“太平保险经纪“提供，请仔细阅读相关《用户服务协议》。点击
            <span class="link2">【每日保险申购】</span>
            后需授权同意使用您的手机号以及实名信息进行投保
          </p>
        </div>
      </div>
      <div class="insurance-message">
        <p>对保险申购有疑问？<span class="link ud-link">点击立刻咨询 >></span></p>
      </div>
      <van-button
        v-if="insureStatus === '1'"
        type="primary"
        size="small"
        style="margin-left: 28px"
        @click="refreshInsure"
      >
        保单刷新
      </van-button>
    </div>
  </div>
</template>
<script>
import { listSubTask, addHardwareLog } from '@/api/api.js'
import { getInsureUrl, getInsureResult, getInsureStatus } from '@/api/insurance.js'
import { Dialog } from 'vant'
import UA from 'ua-device'
export default {
  props: ['tenCode'],
  data() {
    return {
      insureStatus: '', // "0"：待执行; "1"：出单中; "2" : 已完成; "3": 无法投保
      billNo: '', // 保险流水号
      isKSZPFinished: false
    }
  },
  methods: {
    checkTaskStatus() {
      if (
        this.$store.state.batchCode &&
        this.$store.state.taskCode &&
        this.$store.state.empPk &&
        this.$store.state.userInfo.attdate
      ) {
        return listSubTask().then((res) => {
          if (res.data.data.length) {
            this.isKSZPFinished = res.data.data.every((item) => item.isDone)
          } else {
            this.isKSZPFinished = false
          }
        })
      }
    },
    // 保险
    async toInsurance() {
      if (['1', '2', '3'].includes(this.insureStatus)) return
      await this.checkTaskStatus()

      if (!this.isKSZPFinished) {
        Dialog.alert({
          title: '提示',
          message: '完成工牌照/人像照后才能进行每日保险申购哦',
          confirmButtonColor: '#24ADFE'
        })
        return
      }

      Dialog.confirm({
        title: '授权确认',
        message: `<div>是否同意授权使用您的手机号以及实名身份信息进行投保</div><div style="color: #999; margin-top: 10px;">您即将跳转至太平经纪投保平台。请注意，该系统的内容、服务及安全性我们无法保证，相关风险需由您自行承担。</div>`,
        confirmButtonText: '同意授权',
        cancelButtonText: '取消',
        // cancelButtonColor: '#ee0a24',
        confirmButtonColor: '#24ADFE'
      })
        .then(async () => {
          // on confirm
          console.log('同意授权')
          this.getUserAgent(`2-立即投保`)
          this.getInsuranceLink()
        })
        .catch(() => {
          // on cancel
          console.log('取消')
          this.getUserAgent(`2-立即投保-不考虑`)
        })
    },
    // 获取保险链接、状态接口参数
    getInsureParams() {
      const userInfo = this.$store.state.userInfo
      const {
        userCode: empPk,
        userName: empName,
        userIdcard: empIdCode,
        userTel: empPhone,
        projectCode,
        projectName,
        terminalCode,
        terminalName,
        terminalLongitude: terminalLon,
        terminalLatitude: terminalLat,
        taskBatchCode,
        taskPackageCode,
        scheduleId,
        mustPolicy: isMust
      } = userInfo

      return {
        empPk,
        empName,
        empIdCode,
        empPhone,
        policyName: '安心用工保',
        projectCode,
        projectName,
        terminalCode,
        terminalName,
        terminalLon,
        terminalLat,
        taskBatchCode,
        taskPackageCode,
        scheduleId,
        isMust,
        userLat: this.$store.state.lat,
        userLon: this.$store.state.lng,
        tenCode: this.tenCode,
        appCode: 'MiKa'
      }
    },
    // 获取保险链接
    getInsuranceLink() {
      const params = this.getInsureParams()
      getInsureUrl({
        ...params,
        pageCallbackUrl: this.$store.state?.userInfo?.backUrl || location.href
      }).then((res) => {
        if (res.data.success) {
          console.log(' res.data.url: ', res.data.url)
          window.location.href = res.data.data.url
        }
      })
    },

    // 获取保险状态
    getInsureStatus() {
      const params = this.getInsureParams()
      getInsureStatus(params)
        .then((res) => {
          this.$nextTick(() => {
            this.getBoxHeight()
          })
          if (res.data.success) {
            this.insureStatus = res.data.data.status
            this.billNo = res.data.data.billNo
            this.$emit('getInsureStatus', this.insureStatus)
          } else {
            Dialog.alert({
              title: '提示',
              message: res.data.errorMessage,
              confirmButtonColor: '#24ADFE'
            })
          }
        })
        .catch((err) => {
          console.log('err: ', err)
          this.$nextTick(() => {
            this.getBoxHeight()
          })
        })
    },

    // 刷新保单状态
    async refreshInsure() {
      await getInsureResult({ empPk: this.$store.state.empPk })
      this.getInsureStatus()
    },

    // 获取高度
    getBoxHeight() {
      if (this.$refs.insuranceBox.classList.contains('recommend-box')) {
        this.$emit('getRecommendHeight', 0)
      } else {
        this.$emit('getRecommendHeight', 0)
      }
    },
    // 获取设备信息
    async getUserAgent(msg) {
      // //调用上传设备信息 ip接口 用户信息
      var output = new UA(navigator.userAgent) //UA获取到的对象
      var sss = navigator.userAgent.split(';')
      //判断UA里边有没有Build信息，通过这个拿到安卓的具体机型
      var i = -1
      sss.forEach((element, index) => {
        if (element.includes('Build/')) {
          i = index
        }
      })
      let newMobile //如果是安卓手机返回安卓的型号
      if (i > -1) {
        newMobile = sss[i].substring(0, sss[i].indexOf('Build/'))
      }
      // console.log(output.device.identified ? output.device.model : newMobile)//设备型号
      // console.log(output.browser.name + ' ' + output.browser.version.original)//浏览器版本
      // console.log(output.os.name + ' ' + output.os.version.original)//系统版本
      let ip = ''
      try {
        ip = returnCitySN.cip
      } catch {}
      await addHardwareLog({
        funTitle: this.$store.state.taskCode + this.$store.state.taskTitle + msg,
        userAgents: navigator.userAgent,
        resolvingPower: `${screen.width}x${window.screen.height}`,
        ip: ip,
        userCode: this.$store.state.openOrScheduleId.openId, //传的字段是userCode实际传的是openId
        browser: output.browser.name + ' ' + output.browser.version.original,
        os: output.os.name + ' ' + output.os.version.original,
        device: output.device.identified ? output.device.model : newMobile
      })
    }
  },
  computed: {
    isMust() {
      return this.$store.state.userInfo.mustPolicy == '1'
    }
  },
  mounted() {
    this.getInsureStatus()
  }
}
</script>
<style lang="less" scoped>
.flexBox {
  display: flex;
}
.cardBox {
  background: #fff;
  margin-top: 10px;
  padding: 17px 11.9% 17px 7.2%;
  line-height: 25px;
  color: #333;
  font-weight: 900;
  border-radius: 4px;
  position: relative;
  &.disabled {
    color: #999;
  }
  .header {
    display: none;
  }
  .indexBox {
    position: relative;
    .required {
      position: absolute;
      color: #ff4d4f;
      left: -12px;
    }
  }
  .arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 3.3%;
    top: 21px;
    img {
      display: block;
    }
  }
  .tips {
    background: #f6ffed;
    border: 1px solid #b7eb8f;
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    color: #52c41a;
    font-weight: 400;
    margin-left: 10px;
    padding: 3px 13px;
  }
  .tipsnotstart {
    background: #ffffff;
    border: 1px solid #9da4b1;
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    color: #9da4b1;
    font-weight: 400;
    margin-left: 10px;
    padding: 3px 13px;
  }
  .tipsend {
    background: #ffffff;
    border: 1px solid #ff6633;
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    color: #ff6633;
    font-weight: 400;
    margin-left: 10px;
    padding: 3px 13px;
  }
  .tipsgoon {
    background: #ffffff;
    border: 1px solid #4badff;
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    color: #4badff;
    font-weight: 400;
    margin-left: 10px;
    padding: 3px 13px;
  }
}
.insurance-message {
  font-size: 12px;
  color: #999;
  background-color: #fff;
  padding-left: 28px;
  font-weight: normal;
  p {
    margin: 10px 0;
  }
  .link {
    color: #24adfe;
  }
  .link2 {
    color: #1238a8;
  }
}

.recommend-box {
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  background-color: #fff;
  padding: 0 0 20px;
  .header {
    display: flex;
    height: 36px;
    align-items: center;
    padding: 0 10px;
    background-color: rgba(245, 154, 35, 0.2);
    font-size: 14px;
    color: #f59a23;
    margin-bottom: 20px;
  }
  .indexBox .required {
    display: none;
  }
  .arrow {
    top: 56px;
  }
}
</style>
