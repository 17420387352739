const gobal = {
    //经测试ios在app中挂载一次即可 但是android每个页面需要重新挂载才生效
    controlOptionMenu: () => {
        function onBridgeReady() {
            if (location.origin !== "https://wx-coll-con.uat.sims-cn.com" && location.origin !== "https://dxydev-8081-mc01.dev.sims-cn.com") {
                WeixinJSBridge.call('hideOptionMenu')//禁用分享复制链接等
                WeixinJSBridge.call('hideToolbar')//取消底部导航栏 但是现在多数版本已经不支持了
            }
        }
        if (typeof WeixinJSBridge == 'undefined') {
            if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
            } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
            }
        } else {
            onBridgeReady()
        }
    }
}
export default gobal 