export default {
    namespaced: true,//开启命名空间 
    state: {
        //任务执行时先在入口页面设置为true  进入详情页后如果页面属性 非缓存的cached会变回false 说明缓存丢失 在恢复数据得时候再重新设置为true
        cached: false,  //模块中的状态   页面调用this.$store.state.noCache.cached
    },
    mutations: {
        //修改模块中的状态   页面调用this.$store.commit('noCache/cached',false)
        cached(state, cached) {
            state.cached = cached;
        }
    },
    actions: {},
    getters: {}
}

